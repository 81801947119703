.cs-pointer {
  cursor: pointer;
}

.menu .item div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cs-mt-3 {
  margin-top: 16px;
}

.cs-text-center {
  text-align: center;
}



.cs-app {
  margin-top: 70px;
  margin-bottom: 70px;
  height: '100%';
}

.cs-circle-sm {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.cs-selected {
  border-bottom: 6px solid #007bff;
}

.cs-bg-primary {
  background-color: #425b68;
}

.cs-bg-primary-light {
  background-color: #6e8896;
}

.cs-bg-primary-dark {
  background-color: #18323d;
}

.cs-bg-secondary {
  background-color: #70bf8f;
}

.cs-bg-secondary-light {
  background-color: #a1f2bf;
}

.cs-bg-secondary-dark {
  background-color: #408e61;
}

.cs-primary-text {
  color: white;
}

.cs-secondary-text {
  color: black;
}

.cs-color-working {
  background-color: #e67e22
}

.cs-color-ready {
  background-color: #43A047
}

.cs-color-delivery {
  background-color: #8e44ad
}

.cs-color-retiro  {
  background-color: #795548
}

.cs-color-disabled {
  background-color: lightgrey;
}

.cs-btn {
  cursor: pointer;
  text-align: center;
  padding: 8px 24px;
}


.cs-pay {
  color: #27ae60 !important;
}

.cs-disabled {
  color: grey !important;
}

.cs-no-pay {
  color: grey;
}

.cs-cancel {
  color: #c0392b
}

.cs-status-next {
  color: #27ae60
}

.cs-login {
  height: 100%;
  min-height: 100vh;
}

.cs-logo {
  margin-top: 40px;
  margin-bottom: 40px;
}

.cs-floating {
  background-color: white;
  position: absolute;
  top: 40px;
  z-index: 1000000000;
}

.cs-hover:hover {
  background-color: #0d47a1;
  color: white;
}

.cs-small {
  width: 50px;
}

.cs-btn-small {
  width: 80px;
}

.cs-circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.cs-circle-content {
  margin-top: 20%;
  color: white;
  text-align: center;
}

.cs-circle-sm {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}