.cs-header {
  height: 70px;
}

.cs-header-body {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.cs-header-center {
  flex-grow: 1;
}
